
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import InsightTypeModel from '@/modules/insights/models/insight-type.model';
import { COLOR_NAME } from '@/modules/common/constants/color-names';
import { INSIGHT_CARD_VALUE } from '@/modules/cluster/constants';

@Component
export default class InsightsClusterCard extends Vue {
    @Prop({ type: Object })
    insightType!: InsightTypeModel;

    @Prop({ type: [Object, String], default: INSIGHT_CARD_VALUE.NA })
    card!: { items: Item[], color: COLOR_NAME, tooltip: string } | INSIGHT_CARD_VALUE;

    get isNa() {
        return this.card === INSIGHT_CARD_VALUE.NA;
    }

    get isNoChanges() {
        return this.card === INSIGHT_CARD_VALUE.NOCHANGES;
    }

    get isRealValue() {
        return !this.isNa && !this.isNoChanges;
    }
}
