
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import InsightFiltersService, { InsightFiltersServiceS } from '@/modules/document-filters/insight-filters.service';
import { InsightType } from '../constants';

@Component
export default class InsightTypeLabel extends Vue {
    @Inject(InsightFiltersServiceS) private insightFiltersService!: InsightFiltersService;

    @Prop({ type: String })
    type!: InsightType;

    get typeColor() {
        // Add more colors from design if needed
        const colorPairs = [
            ['#52A9FF', 'rgba(82, 169, 255, 0.06)'],
            ['#A7881C', 'rgba(255, 238, 82, 0.08)'],
            ['#EE2841', 'rgba(241, 73, 80, 0.06)'],
            ['#555', 'rgba(170, 170, 170, 0.06)'],
            ['#21A433', 'rgba(57, 181, 74, 0.06)'],
            ['#EE7010', 'rgba(200, 90, 10, 0.06)'],
            ['#1FBF9F', 'rgba(23, 161, 121, 0.08)'],
        ];

        const typeIndex = this.insightFiltersService.options.insightTypes.findIndex(t => t.value as InsightType === this.type);
        const colorIndex = typeIndex % colorPairs.length;

        return colorPairs[colorIndex];
    }

    get typeName() {
        const item = this.insightFiltersService.options.insightTypes.find(t => t.value === this.type);
        return item?.name || this.type;
    }
}
