
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import InsightFiltersService, { InsightFiltersServiceS } from '@/modules/document-filters/insight-filters.service';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';

@Component({ components: { CustomSelect } })
export default class InsightsDateRange extends Vue {
    @Inject(InsightFiltersServiceS) private insightFiltersService!: InsightFiltersService;

    get dateRangeItems(): Item[] {
        return this.insightFiltersService.options.dateRange.map(item => ({
            name: this.$t(item.name, [item.value]).toString(),
            value: item.value,
        }));
    }

    get dateRange() {
        return this.insightFiltersService.settings.dateRange;
    }

    set dateRange(v: string) {
        this.insightFiltersService.updateSettings({ dateRange: v });
    }
}
