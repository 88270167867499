
import { Inject } from 'inversify-props';
import { Component, Vue, Prop } from 'vue-property-decorator';
import InsightFiltersService, { InsightFiltersServiceS } from '@/modules/document-filters/insight-filters.service';
import PAGES from '@/modules/common/constants/pages.constant';
import InsightsClusterCard from './insights-cluster-card.vue';
import ClusterInsightsModel from '../../../models/cluster-insights.model';
import ClusterInsightsDocumentModel from '../../../models/cluster-insights-document.model';
import ClusterService, { ClusterServiceS } from '../../../cluster.service';

@Component({
    components: { InsightsClusterCard },
})
export default class InsightsClusterHotelData extends Vue {
    @Inject(InsightFiltersServiceS) private insightFiltersService!: InsightFiltersService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    @Prop({ type: Object })
    hotelData!: ClusterInsightsModel;

    isDocumentLoading = false;

    get insightTypes() {
        return this.insightFiltersService.options.insightTypes.filter(t => t.isActive);
    }

    get isNoData() {
        return !this.hotelData.compsetMain || !Object.keys(this.hotelData.compsetMain).length;
    }

    cardValue(insightType: string) {
        if (!this.hotelData.compsetMain) return null;
        return this.hotelData.compsetMain[insightType as keyof ClusterInsightsDocumentModel];
    }

    // Lazy load of document, send request only if component is in viewport.
    loadViewportedHotel(isVisible: boolean) {
        if (this.isDocumentLoading || !isVisible || this.hotelData.documentLoaded) {
            return;
        }

        this.isDocumentLoading = true;
        this.clusterService
            .loadDaysData(PAGES.INSIGHTS, this.hotelData.hotelId)
            .finally(() => {
                this.isDocumentLoading = false;
            });
    }
}
